<template>
    <div class="app-download-area bg-e3e3e3 pt-100">
        <div class="container">
            <div class="row align-items-center m-0">
                <div class="col-lg-6 col-md-12 p-0">
                    <div class="app-download-image">
                        <img src="../../assets/img/app.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 p-0">
                    <div class="app-download-content">
                        <h2>Descarga Nuestra Aplicación</h2>
                        <p>En Google Play Store o App Store nos encuentras como iCoin App. Registrate y comienza a enviar a tus amigos y familiares.</p>
                        <div class="btn-box">
                            <a href="https://play.google.com/store/apps/details?id=com.icoin.app" class="playstore-btn" target="_blank">
                                <img src="../../assets/img/play-store.png" alt="image">
                                Disponible en
                                <span>Google Play</span>
                            </a>
                            <a href="https://apps.apple.com/app/icoin-app/id6480351025?l=es" class="applestore-btn" target="_blank">
                                <img src="../../assets/img/apple-store.png" alt="image">
                                Disponible en
                                <span>App Store</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MobileApp'
}
</script>